import * as React from 'react';
import { DuaType } from '../../models/dua.model';
import Dua from './Dua';
import { Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

interface IDuasProps {
    duas: DuaType[]
}

const Duas: React.FunctionComponent<IDuasProps> = ({ duas }) => {
    const { i18n, t } = useTranslation();
    const duaCategories = [...new Set(duas.map(item => item.ta_Category))];
    let accordionCounter: number = 0;
    const renderDua = (): JSX.Element[] => {
        return duaCategories.map(duaCategory => {
            accordionCounter++;
            let subDuas: DuaType[];
            subDuas = duas.filter(
                subduas => subduas.ta_Category === duaCategory
            )
            return <Accordion.Item eventKey={accordionCounter.toString()}  id={accordionCounter.toString()} className="shadow p-1 mb-3 bg-body rounded border border-info">
                <Accordion.Header id={subDuas[0].PartitionKey} className="duatamil">{accordionCounter.toString()}. {i18n.language === "en" ? subDuas[0].en_Category : subDuas[0].ta_Category}
                    <a id={"header" + subDuas[0].PartitionKey} href={"#" + subDuas[0].PartitionKey} className="hash-link" aria-label={"Direct link to " + duaCategory} title={"Direct link to " + duaCategory}></a>
                </Accordion.Header>
                <Accordion.Body>
                    {
                        subDuas.map(dua => {
                            return <Dua dua={dua} />
                        })
                    }
                </Accordion.Body>
            </Accordion.Item >
        });
    };
    return (
        <>
            {
                renderDua()
            }
        </>
    );
};
export default Duas;
