import React from "react";
import Footer from "../components/Footer";
import { Col, Container, Row, Image, Carousel, Card, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IMAGEBASE } from "../common/constant";
import { LinkContainer } from "react-router-bootstrap";

export default function Home() {
  const { i18n, t } = useTranslation();
  let highlights = [{
    header:'The Quran and the Hadith',
    message: 'A man asked to Prophet Mohammad(PBUH) that which of the people are most deserving of my best companionship. He said: Your Mother, Your Mother and Your Mother, then Your Father and then the next close and the next closest. I believe those who really know the status of Parents then they will never do anything wrong or any disheartening things to them',
    source: 'It was narrated by <cite title="Source Title">Hazrat Abu Huraira(RA)</cite>'
  },
  {
    header:'The Quran and the Hadith',
    message: 'There are two words which are light on the tongue, heavy on the scale, and loved by the Most Merciful: SubhanAllahi wa bihamdi, SubhanAllahi al-azeem (Glorified is Allah and praised is He, Glorified is Allah the Most Great).',
    source: 'It was narrated in <cite title="Source Title">Bukhari and Muslim</cite>'
  }]
  return (
    <>
      {/* <Header /> */}
      <br />
      <Container className='mt-5' style={{ width: '100%' }}>
        <Row>
          <Col >
            <Image src={IMAGEBASE + "homepage.jpeg"} width="100%" fluid rounded />
          </Col>
        </Row>
        <Row className='mt-2' align="center">
          <Col style={{ cursor: 'pointer' }}>
            <LinkContainer to="/dua" style={{ height: '100%' }} >
              <Card>
                <Card.Img variant="top" src={IMAGEBASE + "dua-tiles-286x180.png"} />
                <Card.Body className="tiles-body">
                  <Card.Text><b>{t("dua")}</b></Card.Text>
                </Card.Body>
              </Card>
            </LinkContainer>
          </Col>
          <Col style={{ cursor: 'pointer', paddingLeft: 'inherit' }}>
            <LinkContainer to="/prayertimes" style={{ height: '100%' }} >
              <Card >
                <Card.Img variant="top" src={IMAGEBASE + "times-tiles-286x180.png"} />
                <Card.Body className="tiles-body">
                  <Card.Text><b>{t("prayertimes")}</b></Card.Text>
                </Card.Body>
              </Card>
            </LinkContainer>
          </Col>
          <Col style={{ cursor: 'pointer', paddingLeft: 'inherit' }}>
            <LinkContainer to="/aboutus" style={{ height: '100%' }} >
              <Card>
                <Card.Img variant="top" src={IMAGEBASE + "aboutus-tiles-286x180.png"} />
                <Card.Body className="tiles-body">
                  <Card.Text><b>{t("about")}</b></Card.Text>
                </Card.Body>
              </Card>
            </LinkContainer>
          </Col>
        </Row>
        <Row>
          <Col className='mt-2' >
            <Carousel data-bs-theme="dark" controls={false}>
              {highlights.map(({ header, message, source }) => (
                <Carousel.Item interval={20000} >
                    <Card bg="light" style={{ color: "black", fontWeight: 400 }}>
                      <Card.Header>{header}</Card.Header>
                      <Card.Body className="text-sm-start" >
                        <blockquote className="blockquote mb-0">
                          <p>
                            {message}
                          </p>
                          <footer className="blockquote-footer"  dangerouslySetInnerHTML={{ __html: source }} >
                          </footer>
                        </blockquote>
                      </Card.Body>
                    </Card>
                </Carousel.Item>
              ))}
            </Carousel>
          </Col>
        </Row>
        <Row style={{ fontSize: "large", fontFamily: "Inter, sans-serif" }}>
          <Col dangerouslySetInnerHTML={{ __html: t("welcome") }} className='mt-3'>
          </Col>
        </Row>
        <Row>
          <Col>
            <Carousel>
              <Carousel.Item interval={5000}>
                <img
                  className="d-block w-100"
                  src={IMAGEBASE + "jummah-300x115.jpg"}
                  alt="Image One"
                />
                <Carousel.Caption>
                  <p className="text-md-start">ஜும்ஆ நிகழ்வு, ஒவ்வொரு வெள்ளிக்கிழமையும் சுமார் 40 ஆண்கள் ஜமாத்தில் கூடுகிறார்கள்.</p>
                  {/* <p>Sample Text for Image One</p> */}
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={5000}>
                <img
                  className="d-block w-100"
                  src={IMAGEBASE + "school-300x115.jpg"}
                  alt="Image Two"
                />
                <Carousel.Caption>
                  <p className="text-md-start">ஒவ்வொரு ஆண்டும் ஆண் மாணவர்களுக்கு கோடைகால பயிற்சி முகாம்</p>
                  {/* <p>Sample Text for Image Two</p> */}
                </Carousel.Caption>
              </Carousel.Item>
              <Carousel.Item interval={5000}>
                <img
                  className="d-block w-100"
                  src={IMAGEBASE + "Tarbiyah.jpg"}
                  alt="Image Two"
                />
                <Carousel.Caption>
                  <p className="text-md-start">ஒவ்வொரு ஞாயிற்றுக்கிழமையும் சுபஹ் தொழுகைக்கு பிறகு ஆண்களுக்கான தர்பியா</p>
                  {/* <p>Sample Text for Image Two</p> */}
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </Col>
        </Row>
      </Container >

    </>
  );
}